import React from 'react'

// App components
import Accordian from '../../components/Accordian'
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'

// App utils
import { themeStyles } from '../../utils/theme'

const accordianItems = [
  {
    title: 'Loose Band or Bracket',
    body: (
      <p>
        If a band or bracket comes loose from the tooth, call our office for an
        appointment. While a tight band or bracket actually protects a tooth
        from decay, a loose band or bracket may trap food that can cause tooth
        decay. If the band or bracket is still attached to a wire, leave it in
        place and apply wax if there is discomfort. If the band or bracket comes
        completely out, place it in an envelope and call for an appointment.
      </p>
    ),
  },
  {
    title: 'Broken Archwire',
    body: (
      <p>
        If the main archwire breaks (the one that goes all the way around the
        outside of your braces), call our office to schedule a time to fix the
        wire for you. If the wire is poking, place a large ball of wax on the
        wire so you are comfortable until able to see us to address the problem.
      </p>
    ),
  },
  {
    title: 'Loose Wire',
    body: (
      <p>
        When teeth begin to straighten, wires can sometimes shift and slide
        around. If your wire comes out of the back tube, then you may try to
        place the wire back into the tube. If unable to address the issue on
        your own, then place wax and notify your dental assistant at your next
        appointment. If you are in pain, then you may also call for an
        appointment.
      </p>
    ),
  },
  {
    title: 'Poking Wire',
    body: (
      <p>
        Sometimes a poking wire can be safely turned down so that it no longer
        causes you discomfort. Try to tuck the wire back in and out of the way
        with the blunt end of a toothbrush, or some other smooth object. If you
        are unable to take care of a poking wire, apply a large ball of wax and
        call our office for an appointment at the next available opening.
      </p>
    ),
  },
  {
    title: 'Lost Tie-wire or Elastic Tie',
    body: (
      <div>
        <p>
          Around each brace is a small elastic tie (some are colored and some
          are clear) that holds the wire in place. Should one of these ties fall
          off the brace, please notify one of our dental assistants at your next
          appointment. This is not an emergency that requires immediate
          attention.
        </p>
        <p>
          Although this is not an emergency that requires immediate attention,
          please notify one of our dental assistants at your next appointment.
        </p>
      </div>
    ),
  },
  {
    title: 'Soreness',
    body: (
      <p>
        Soreness during your first few days in braces is to be expected. Eating
        soft and cooler foods will certainly help with the initial discomfort.
        You may use a combination of Advil and Tylenol (provided you have no
        medical contraindications to these over-the-counter medications) to help
        with the initial soreness.
      </p>
    ),
  },
  {
    title: 'Accidents Involving Teeth',
    body: (
      <div>
        <p>Contact our office immediately, or contact your general dentist.</p>

        <p>
          Please remember: If you are involved in contact sports, a
          "braces-friendly" mouth guard can be fit over your braces for added
          protection.
        </p>
      </div>
    ),
  },
]
class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <PageTitle
            title="Troubleshooting Your Braces"
            bannerKey={'RESOURCES'}
          />
          <div
            css={[
              themeStyles.textPadding,
              themeStyles.innerTextBreak,
              themeStyles.elementMargin,
            ]}
          >
            <Accordian items={accordianItems} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default Page
